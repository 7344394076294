import $ from "jquery";
function toggleNav() {
  var body = document.body;
  var hamburger = document.getElementById("navBtn");
  var navitem = document.getElementById("navlist");
  hamburger.addEventListener("click", function () {
    body.classList.toggle("nav-open");
  });
  navitem.addEventListener("click", function () {
    body.classList.remove("nav-open");
  });
}
toggleNav();
